<template>
  <Loading />
</template>

<script>
import api from '@/api/index'

export default {
  name: 'LoginToken',

  props: ['token'],

  computed: {
    isLogin() {
      return this.$store.getters.isLogin
    }
  },

  watch: {
    isLogin(val) {
      if(val) {
        if(this.$route.query.isCheckout) {
          this.$router.push({ name: 'checkout' })
        } else {
          this.$router.push({ name: 'home' })
        }
      }
    }
  },

  async created() {
    const form = new FormData()
    form.append('username', 'webViewReact')
    form.append('password', this.token)

    await this.$store.dispatch('login', { form })
  }
}
</script>
